<template>
  <div class="container">
    <img class="bg" src="../../assets/bg.jpg" alt="">
    <div class="center">
      <div class="center-left">
        <img src="../../assets/login_pic.png" alt="">
      </div>
      <div class="account" v-if="!showWxCode">
        <div class="title">账号登录</div>
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
          <a-form-item label="">
            <a-input
              class="common-input"
              placeholder="请输入登录账号"
              v-decorator="['username', { rules: [{ required: true, message: '请输入登录账号!' }] }]"
            />
          </a-form-item>
          <a-form-item label="">
            <a-input
              class="common-input"
              type="password"
              placeholder="请输入登录密码"
              v-decorator="['password', { rules: [{ required: true, message: '请输入登录密码!' }] }]"
            />
          </a-form-item>
          <a-form-item label="" class="test-code">
            <div class="ccode">
              <a-input
                class="common-input"
                style="width:159px"
                placeholder="图形验证码"
                @change="inputCodeChange"
                v-decorator="['captcha', { rules: [{ required: true, message: '请输入图形验证码!' },{validator: validateInputCode}] }]"
              />
              <div class="pint">
                <j-graphic-code @success="generateCode" ref="jgraphicCodeRef" style="float: right" remote></j-graphic-code>
              </div>
            </div>
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 12, offset: 0 }">
            <a-button class="common-input btn" type="primary" html-type="submit">
              登录
            </a-button>
            <!-- <a-button v-else class="common-input btn-disabled" type="primary">
              登录
            </a-button> -->
          </a-form-item>
          <!-- <div>
            <a-checkbox class="proto" @change="onChange">
              我已阅读并同意<a href="#">法律声明及隐私权政策</a>和<a href="#">用户协议</a> 
            </a-checkbox>
          </div> -->
          <!-- <div>
            <a-button @click="handleCheck" class="saoyisao" type="link"><a-icon :style="{ fontSize: '18px' }" type="wechat" /> 微信扫码登录</a-button>
            <a-button v-else disabled class="saoyisao" type="link"><a-icon :style="{ fontSize: '18px' }" type="wechat" /> 微信扫码登录</a-button>
          </div> -->
        </a-form>
        <div class="no-account" @click="noAccount">没有账号？</div>
        <img class="wx-login-picture" @click="handleCheck" src="../../assets/wx_login.png" alt="">
      </div>
      <div class="account" style="width:416px" v-else>
        <div class="title">扫码登录</div>
        
        <div class="wx-login">
          <div class="wx-login-container" v-if="showWxCode">
            <div class="wlc-title">请使用微信扫描二维码</div>
            <div class="wx-login-content">
              
              <div class="wx-code"><div id="qrcode" ref="qrcode"></div></div>
              
              <div class="wx-login-reget" v-if="isReget">
                <div style="cursor:pointer" @click="reWxGetCode">
                  <div style="text-align:center"><a-icon type="reload" style="color:#FFFFFF;font-size:50px" /></div>
                  <div style="text-align:cener;color:#FFFFFF;font-size:14px;margin-top:16px">点击刷新二维码</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="no-account" @click="noAccount">没有账号？</div>
        <img class="wx-login-picture" @click="closeWxCode" src="../../assets/account_login.png" alt="">
      </div>
    </div>
    <div class="remark-number">Copyright © 2020 四川省智赋云科技有限公司版权所有</div>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import { mapActions } from "vuex"
import JGraphicCode from '@/components/JGraphicCode'
import Vue from 'vue'
import {
  getWxCode,
  isSuccessCode,
  login,
} from '@/api/login'
import {
  checkBindWxMq
} from '@/api/api'
export default {
  name: 'Login',
  components: {
    JGraphicCode
  },
  data () {
    return {
      formLayout: 'horizontal',
      form: this.$form.createForm(this),
      checked: false,
      timer: 60,
      qrcode: null,
      showWxCode: false,
      isReget: false,
      wxCodeTimer: null,
      verifiedCode: "",
      inputCodeContent: "",
      intervalTimer: null,
      wxCodeKey: '',
      loginCodeKey: '',
      loginParams: {
        checkKey: '',
        loginType: 'channel'
      }
    }
  },
  created () {
    this.getCodeUrl()
    // this.checkBindWxMq()
    window.localStorage.clear();
    window.sessionStorage.clear();
  },
  destroyed () {
    if (this.intervalTimer!==null) {
      clearInterval(this.intervalTimer)
    }
  },
  methods: {
    ...mapActions([ "Login", "WxLogin", "Logout" ]),
    // 查询用户是否绑定微信
    checkBindWxMq () {
      checkBindWxMq().then(res => {
        console.log(res)
      })
    },
    // 生成二维码
    makeQrcode (text) {
      if(this.qrcode){
        this.qrcode.clear();
        this.qrcode.makeCode(text);
      }else{
        this.qrcode = new QRCode('qrcode',{
          width: 200, // 设置宽度，单位像素
          height: 200, // 设置高度，单位像素
          text  // 设置二维码内容或跳转地址
        })
      }
    },
    // 获取二维码链接
    getCodeUrl () {
      getWxCode().then(res => {
        // console.log(res)
        let url = res.result.QRCode.url
        this.wxCodeKey = res.result.key
        this.makeQrcode(url)
      })
    },
    //打开查看二维码
    handleCheck(){
      this.showWxCode = true
      this.isReget = false
      this.$nextTick(() => {
        this.getCodeUrl();
      })
      this.wxCodeTimer = setTimeout(() => {
        this.isReget = true
      },60000)
      this.validWxCodeSuccess()
    },
    // 重新获取
    reWxGetCode () {
      this.handleCheck()
    },
    // 关闭微信二维码
    closeWxCode () {
      this.qrcode = null
      this.showWxCode = false
      if (this.wxCodeTimer!==null) {
        clearTimeout(this.wxCodeTimer)
      }
      if (this.intervalTimer!==null) {
        clearInterval(this.intervalTimer)
      }
    },
    // 校验扫描二维码是否成功
    validWxCodeSuccess () {
      this.intervalTimer = setInterval(() => {
        isSuccessCode(this.wxCodeKey).then(res => {
          // console.log('检验是否成功',res)
          if (res.success) {
            clearInterval(this.intervalTimer)
            this.WxLogin(res).then(
              this.$router.push('/home')
            )
          }
        })
      },1000)
    },
    // 获取验证码 验证验证码是否正确
    generateCode(value,checkKey){
      this.loginParams.checkKey = checkKey
      this.verifiedCode = value.toLowerCase()
    },
    validateInputCode(rule,value,callback){
      if(!value || this.verifiedCode==this.inputCodeContent){
        callback();
      }else{
        callback("您输入的验证码不正确!");
      }
    },
    inputCodeChange(e){
      this.inputCodeContent = e.target.value
      if(!e.target.value||0==e.target.value){
      }else{
        this.inputCodeContent = this.inputCodeContent.toLowerCase()
      }
    },
    // 没有账号
    noAccount () {
      this.$message.warning('请联系客服 400-996-2055')
    },
    // 登录
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let params = Object.assign(this.loginParams, values)
          params.username = params.username.trim()
          params.password = params.password.trim()
          this.Login(params).then(res => {
            if (res.success) {
              window.localStorage.setItem("submenu", 'raskManage');
              this.$router.push('/home')
            }
          })
        }
      });
    },
    // 同意阅读协议
    onChange(e) {
      this.checked = e.target.checked
    },
  }
}
</script>
<style lang="less" scoped>
.container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .bg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
  }
  .center{
    display: flex;
    align-items: center;
    justify-content: center;
    .account{
      background: #FFFFFF;
      box-shadow: 0px 0px 21px 0px rgba(92, 97, 104, 0.18);
      border-radius: 4px;
      margin: 50px 0px 45px 240px;
      padding: 44px;
      position: relative;
      .wx-login-picture{
        position: absolute;
        top: -1px;
        right: -1px;
        cursor: pointer;
      }
      .no-account{
        color: #2468F2;
        font-size: 14px;
        cursor: pointer;
        margin-top: 10px;
      }
      .title{
        // text-align: center;
        font-size: 24px;
        font-weight: 500;
        color: #212529;
        margin-bottom: 30px;
      }
      .tips{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #909399;
        margin-bottom: 48px;
        text-align: center;
        line-height: 14px;
      }
      .common-input{
        width:328px;
        height:40px;
      }
      .test-code{
        position: relative;
      }
      .ccode{
        width: 328px;
        display: flex;
        justify-content: space-between;
      }
      .pint{
        cursor: pointer;
      }
      .btn{
        color: #FFFFFF;
        background: #2468F2;
        border: 0;
      }
      .btn-disabled{
        background: #D3D6DC;
        border: 0;
      }
      .proto{
        font-size: 12px;
        margin-bottom: 16px;
        a{
          color: #3c58f2;
        }
      }
      .saoyisao{
        width: 336px;
        text-align: center;
        color: #3c58f2;
        margin-bottom: 30px;
      }
    }
  }
  .wx-login{
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 328px;
    height: 261px;
    .wx-login-container{
      .wlc-title{
        text-align: center;
        color: #757575;
        font-size: 14px;
      }
      .close-arrow{
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 20px;
      }
      .wx-login-content{
        margin: 0 auto;
        position: relative;
        .wx-login-title{
          text-align: center;
          font-size: 20px;
          color: #FFFFFF;
          margin-bottom: 20px;
        }
        .wx-code{
          background: #FFFFFF;
          padding: 20px;
          margin: 0 auto;
        }
        .wx-login-footer{
          font-size: 12px;
          color: #FFFFFF;
          text-align: center;
          margin-top: 20px;
        }
        .wx-login-reget{
          position: absolute;
          top: 0;
          width: 240px;
          height: 240px;
          background: rgba(0,0,0,0.6);
          z-index: 60;
          display: flex;
          justify-content: center;
          align-items: center;
          .wx-login-getbtn{
            background: #3c58f2;
            width: 200px;
            height: 40px;
            font-size: 14px;
            padding: 10px;
          }
        }
      }
    }
  }
  .remark-number{
    position: fixed;
    bottom: 94px;
    font-size: 14px;
    color: #4D4D4D;
    line-height: 14px;
    width: 452px;
    text-align: center;
    margin-top: 25px;
  }
}
</style>
